<template>
  <div class="KpiData_wrapper">
    <el-tabs type="card" v-model="currView">
      <el-tab-pane label="直播订单" name="LiveOrder"></el-tab-pane>
      <el-tab-pane label="过程订单" name="ProcessOrder"></el-tab-pane>
      <el-tab-pane label="秒杀订单" name="SeckillOrder"></el-tab-pane>
    </el-tabs>
    <keep-alive>
      <component :is="currView" :activityID="activityID"></component>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "Achievement", // 
  components: {
    LiveOrder: () => import("./AchievementChildren/LiveOrder"),
    ProcessOrder: () => import("./AchievementChildren/ProcessOrder"),
    SeckillOrder: () => import("./AchievementChildren/SeckillOrder"),
  },
  props: {
    activityID: String
  },
  data() {
    return {
      currView: "LiveOrder"
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.Achievement_wrapper {
}
</style>
